.navigation {
    display: flex;
    flex-wrap: wrap;
    column-gap: 45px;
    margin-bottom: -45px;
}

.navigation--centered-w {
    justify-content: center;
}

.navigation-break {
    flex-basis: 100%;
    height: 0;
}

.navigation__card {
    width: 140px;
    height: 140px;
    background-color: #1a1f32;
    box-shadow: rgba(30, 136, 229, 0.7) 0px 8px 40px 0px;
    border-radius: 12px;
    padding: 50px 2px 10px 8px;
    margin-bottom: 45px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 1200px) {
        width: 240px;
        height: 240px;
        padding: 95px 45px 35px 45px;
    }
}

.navigation__card:hover {
    background-color: #252a47;
    cursor: pointer;
}

.navigation__card--big {
    flex-direction: row;
    align-items: center;
    column-gap: 21px;
    width: 325px;
    padding: 27px;

    @media (min-width: 1200px) {
        width: 525px;
        height: 178px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
}

.navigation__card--extra {
    width: 325px;
    height: auto;
    padding-top: 26px;
    padding-bottom: 26px;
    row-gap: 15px;

    @media (min-width: 1200px) {
        width: 525px;
        padding: 27px 30px 35px;
    }
}

.navigation__card--flat {
    width: 325px;
    height: 95px;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    & .navigation__card-icon {
        left: 50%;
        top: 50%;
        transform: translate(-30%, -50%);
    }

    @media (min-width: 1200px) {
        width: 240px;
        height: 240px;

        flex-direction: column;
        justify-content: center;

        row-gap: 15px;

        & .navigation__card-icon {
            position: static;
            transform: none;
        }

        & .navigation__card__title {
            text-align: center;
        }
    }
}

.navigation__card--red {
    border: 1px solid rgba(255, 84, 71, 0.4);

    @media (min-width: 1200px) {
        border: none;
    }
}

.navigation__card--green {
    border: 1px solid rgba(15, 247, 81, 0.4);

    @media (min-width: 1200px) {
        border: none;
    }
}

.navigation__card--blue {
    border: 1px solid rgba(30, 136, 229, 0.4);

    @media (min-width: 1200px) {
        border: none;
    }
}

.navigation__card--yellow {
    border: 1px solid rgba(252, 224, 29, 0.4);

    @media (min-width: 1200px) {
        border: none;
    }
}

.navigation__card--gray {
    border: 1px solid rgba(117, 117, 117, 1);

    @media (min-width: 1200px) {
        border: none;
    }
}

.navigation__card-label {
    background-color: #1f84dd;
    border-radius: 15px;
    position: absolute;
    top: -10px;
    left: 5px;
    padding: 0 10px;
    font-size: 14px;

    @media (min-width: 1200px) {
        font-size: 16px;
    }
}

.navigation__card-label--centered-w {
    left: 50%;
    transform: translateX(-50%);
}

.navigation__card-label--padded {
    padding: 0 50px;
}

.navigation__card-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

.navigation__card-icon-wrapper {
    width: 40%;
    position: relative;

    @media (min-width: 1200px) {
        width: auto;
    }
}

.navigation__card-icon {
    position: absolute;
    right: 10px;
    top: 10px;

    @media (min-width: 1200px) {
        right: 35px;
        top: 25px;
    }
}

.navigation__card-icon--static {
    position: static;
}

.navigation__card__title {
    font-size: 16px;
    font-weight: 300;
    line-height: 15px;

    @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 20px;
    }
}

.navigation__card__title--centered-h {
    margin-top: 10px;
}

.navigation__card__text {
    font-size: 12px;
    font-weight: 100;
    line-height: 120%;
    color: rgba(224, 224, 224, 0.7);

    @media (min-width: 1200px) {
        font-size: 14px;
        line-height: 16px;
    }
}

.navigation__card__badges {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 25px;

    @media (min-width: 1200px) {
        column-gap: normal;
        justify-content: space-between;
    }
}

.navigation__card__badge {
    width: 90px;
    height: 90px;
    background-color: rgba(18, 18, 32, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        width: 156px;
        height: 121px;
        font-size: 20px;
    }
}